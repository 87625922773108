<script setup lang="ts">
import type { ComputedRef } from 'vue';
import type { Notification } from '@shopware-pwa/composables-next';

defineEmits<{
  (e: 'click:close', id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const colorCssMap = {
  info: 'blue',
  success: 'green',
  warning: 'orange',
  danger: 'red',
};

// i-carbon icons map
const iconsMap = {
  info: 'information',
  success: 'checkmark',
  warning: 'warning-alt',
  danger: 'close-outline',
};

const themeTypeColor: ComputedRef<string> = computed(
  () => colorCssMap[props.notification.type] || 'blue',
);
const icon = computed(() => iconsMap[props.notification.type] || 'information');
</script>
<template>
  <!-- don't remove; enforce unocss to include dynamically used classes: class="bg-blue-100 bg-green-100 bg-orange-100 bg-red-100" -->
  <div
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :data-testid="`notification-element-${notification.type}`"
    class="mb-4 flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
    role="alert"
  >
    <div
      :class="`text-${themeTypeColor}-500 bg-${themeTypeColor}-100 dark:bg-${themeTypeColor}-800 dark:text-${themeTypeColor}-200`"
      class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg"
    >
      <!-- don't remove; enforce unocss to include dynamically used classes: class="i-carbon-information i-carbon-checkmark i-carbon-warning-alt i-carbon-close-outline" -->
      <div :class="`h-5 w-5 i-carbon-${icon}`" />
    </div>
    <div
      data-testid="notification-element-message"
      class="ml-3 text-sm font-normal"
    >
      {{ notification.message }}
    </div>
    <button
      data-testid="notification-element-button"
      type="button"
      class="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
      :data-dismiss-target="`toast-${notification.id}`"
      aria-label="Close notification"
      @click="$emit('click:close', notification.id)"
    >
      <span class="sr-only">{{ $t('close') }}</span>
      <div class="i-carbon-close h-5 w-5" />
    </button>
  </div>
</template>
